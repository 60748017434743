import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Modal, 
  ModalHeader,
  ModalBody,
  FormFeedback,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import SweetAlert from "react-bootstrap-sweetalert"

import { get, post, put } from "helpers/api_helper";

//i18n
import { withTranslation } from "react-i18next";

const ModalDetail = (props) => {
    const [isEdit, setIsEdit] = useState(false);
    const [dataEdit, setDataEdit] = useState();
    const [dataUser, setDataUser] = useState({ id: '' ,name: '', username: '', email: '', phone: '', address: '', relationship: ''});
    const [codeResetPassword, setCodeResetPassword] = useState(null);
    const [codeResetPIN, setCodeResetPIN] = useState(null);
    const [settingModal, setSettingModal] = useState({button: 'show', title: 'Detail Data User'});
    const [confirm_alert, setconfirm_alert] = useState({show: false, edit: '', text: '', value: {}});
    const [message_alert, setmessage_alert] = useState({show: false, text: ''});

    const getDataUser = async (id)=> {
        let response = await get(`user/detail/${id}`);
        let data = response.data;
        
        setDataUser({ id: data.id, name: data.full_name, username: data.username, email: data.email, phone: data.phone, address: data.address, relationship: data.relationship});
    }

    useEffect(() => {
        if(props.idDetail !== null) { getDataUser(props.idDetail) }
        else {
            setSettingModal({...settingModal, button: 'show', title: 'Detail Data User'});
            validationPassword.resetForm({password: '', confirm_password: ''});
            validationPIN.resetForm({pin: '', confirm_pin: ''});
            setCodeResetPassword(null);
            setCodeResetPIN(null);
        }
    }, [props.modalShow]);

    const HandlerEdit = () => { 
        if(!isEdit) { 
            setDataEdit(dataUser);
            setSettingModal({...settingModal, button: 'edit', title: 'Edit Data User'});
        }
        else { 
            setconfirm_alert({...confirm_alert, show: true, edit: 'user', text: 'Are you sure to change data user?'}); 
            setSettingModal({...settingModal, button: 'show', title: 'Detail Data User'});
        }
        setIsEdit(!isEdit);
    };

    const HandlerResetPassword = async () => {
        const body = {
            username_or_phone: dataUser.phone
        };

        let response = await post(`/auth/sent_mail_reset_password`, body);
        if(response) {
            GetCodeResetPassword();
        }
    }

    const HandlerResetPIN = async () => {
        let response = await post(`/user/sent_user_resetpin?id=${dataUser.id}`);
        if(response) {
            GetCodeResetPIN();
        }
    }

    const HandlerSubmitPassword = async (values) => {
        const body = {
            password: values.password,
            password_confirmation: values.confirm_password,
            code: codeResetPassword
        };

        let response = await post(`/auth/reset_password`, body);
        if(response) {
            setCodeResetPassword(null);
            setSettingModal({...settingModal, button: 'show', title: 'Detail Data User'});
            validationPassword.resetForm({password: '', confirm_password: ''});
            setmessage_alert({...message_alert, show: true, text: 'Password has been changed successfully'});
        }
    }

    const HandlerSubmitPin = async (values) => {
        const body = {
            pin: `${values.pin}`,
            pin_confirmation: `${values.confirm_pin}`,
            code: codeResetPIN
        };

        let response = await post(`/auth/reset_pin`, body);
        if(response) {
            setCodeResetPIN(null);
            setSettingModal({...settingModal, button: 'show', title: 'Detail Data User'});
            validationPIN.resetForm({pin: '', confirm_pin: ''});
            setmessage_alert({...message_alert, show: true, text: 'PIN has been changed successfully'});
        }
    }

    const EditDataUser = async () => {
        const body = {
            phone: dataEdit.phone,
            address: dataEdit.address,
        };

        let response = await put(`/user/profile?id=${dataEdit.id}`, body);
        if(response) {
            getDataUser(props.idDetail);
            setmessage_alert({...message_alert, show: true, text: 'Data User has been changed successfully'});
        }
    }

    const GetCodeResetPassword = async () => {
        let response = await get(`user/detail/${dataUser.id}`);
        let data = response.data.forgot_password_code;
        setCodeResetPassword(data);
        setSettingModal({...settingModal, button: 'hidden', title: 'Edit Password User'});
    }

    const GetCodeResetPIN = async () => {
        let response = await get(`user/detail/${dataUser.id}`);
        let data = response.data.reset_pin_code;
        setCodeResetPIN(data);
        setSettingModal({...settingModal, button: 'hidden', title: 'Edit Pin User'});
    }

    const regExp = /\b\d{5}\b/;
    // Form validation 
    const validationPassword = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            password: '',
            confirm_password: '',
        },
        validationSchema: Yup.object().shape({
            password: Yup.string().matches(/^\S*$/, 'Whitespace is not allowed').min(6, "Must be exactly 6 digits").required(
                "This value is required"
            ),
            confirm_password: Yup.string().matches(/^\S*$/, 'Whitespace is not allowed').min(6, "Must be exactly 6 digits").required(
                "This value is required"
                ).when("password", {
                    is: val => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    "Both password need to be the same"
                ),
            }),
        }),
        onSubmit: (values) => {
            setconfirm_alert({...confirm_alert, show: true, edit: 'password', text: 'Are you sure to change password?', value: values});
        }
    });

    const validationPIN = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            pin: '',
            confirm_pin: '',
        },
        validationSchema: Yup.object().shape({
            pin: Yup.number().min(6, "Must be exactly 6 digits").required(
                "This value is required"
            ),
            confirm_pin: Yup.number().min(6, "Must be exactly 6 digits").required(
                "This value is required"
                ).when("pin", {
                is: val => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                [Yup.ref("pin")],
                "Both pin need to be the same"
                ),
            }),
        }),
        onSubmit: (values) => {
            setconfirm_alert({...confirm_alert, show: true, edit: 'pin', text: 'Are you sure to change pin?', value: values});
        }
    });

    const [formValidation, setValidation] = useState({
        fnm: null,
        lnm: null,
        unm: null,
        city: null,
        stateV: null,
    });
    
    function handleSubmit(e) {
        e.preventDefault();
        const modifiedV = { ...formValidation };
        var fnm = document.getElementById("validationTooltip01").value;
        var lnm = document.getElementById("validationTooltip02").value;
        var unm = document.getElementById("validationTooltipUsername").value;
        var city = document.getElementById("validationTooltip03").value;
        var stateV = document.getElementById("validationTooltip04").value;
    
        if (fnm === "") {
            modifiedV["fnm"] = false;
        } else {
            modifiedV["fnm"] = true;
        }
    
        if (lnm === "") {
            modifiedV["lnm"] = false;
        } else {
            modifiedV["lnm"] = true;
        }
    
        if (unm === "") {
            modifiedV["unm"] = false;
        } else {
            modifiedV["unm"] = true;
        }
    
        if (city === "") {
            modifiedV["city"] = false;
        } else {
            modifiedV["city"] = true;
        }
    
        if (stateV === "") {
            modifiedV["stateV"] = false;
        } else {
            modifiedV["stateV"] = true;
        }
        setValidation(modifiedV);
    }
    
    //for change tooltip display propery
    const onChangeValidation = (fieldName, value) => {
        const modifiedV = { ...validation };
        if (value !== "") {
            modifiedV[fieldName] = true;
        } else {
            modifiedV[fieldName] = false;
        }
        setValidation(modifiedV);
    };

    return(
        <div>
            <Modal
                size="md"
                centered={true}
                isOpen={props.modalShow}
                toggle={props.toggleModal}
            >
                <ModalHeader toggle={props.toggleModal}>{settingModal.title}</ModalHeader>
                <ModalBody>
                    {settingModal.button != 'hidden' ? 
                        <Row>
                            <Col md="12">
                                {isEdit ?
                                    <div>
                                        <Row className="mb-3">
                                            <label className="col-md-3 col-form-label">Full Name</label>
                                            <div className="col-md-6">
                                                <Input className="form-control" type="text" value={dataEdit.name} readOnly />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label className="col-md-3 col-form-label">Email</label>
                                            <div className="col-md-6">
                                                <Input className="form-control" type="text" value={dataEdit.email} readOnly/>
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label className="col-md-3 col-form-label">Phone</label>
                                            <div className="col-md-6">
                                                <Input className="form-control" type="text" onChange={(e) => setDataEdit({...dataEdit,phone: e.target.value})} value={dataEdit.phone} />
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <label className="col-md-3 col-form-label">Address</label>
                                            <div className="col-md-6">
                                                <Input className="form-control" type="text" onChange={(e) => setDataEdit({...dataEdit,address: e.target.value})} value={dataEdit.address} />
                                            </div>
                                        </Row>
                                    </div>
                                    :
                                    <table>
                                        <tbody>
                                            <tr><td width={150}>Full Name</td><td>: {dataUser.name}</td></tr>
                                            <tr><td>Username</td><td>: {dataUser.username}</td></tr>
                                            <tr><td>Email</td><td>: {dataUser.email}</td></tr>
                                            <tr><td>Phone</td><td>: {dataUser.phone}</td></tr>
                                            <tr><td>Address</td><td>: {dataUser.address}</td></tr>
                                            <tr><td>Relation</td><td>: {dataUser.relationship}</td></tr>
                                        </tbody>
                                    </table>
                                }
                                {settingModal.button == 'show' ?
                                    <div className="d-flex flex-row justify-content-end">
                                        <button className="btn btn-sm btn-primary" onClick={HandlerResetPIN}>Reset PIN</button>
                                        <button className="btn btn-sm btn-primary mx-1" onClick={HandlerResetPassword}>Reset Password</button>
                                        <button className="btn btn-sm btn-success" onClick={HandlerEdit}><i className="bx bx-edit" /></button>
                                    </div>
                                : null }
                                {settingModal.button == 'edit' ?
                                    <div className="d-flex flex-row justify-content-end">
                                        <button className="btn btn-sm btn-primary mx-1" onClick={HandlerEdit}>Submit</button>
                                        <button className="btn btn-sm btn-danger" onClick={() => {setDataEdit(dataUser); setIsEdit(!isEdit); setSettingModal({...settingModal, button: 'show', title: 'Detail Data User'}); }}>Cancel</button>
                                    </div>
                                : null }
                            </Col>
                        </Row>
                    : null }
                    
                    {codeResetPassword !== null ? 
                        <Row>
                            <Col md="12" className="justify-content-center">
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    validationPassword.handleSubmit();
                                    return false;
                                }}>
                                <Row className="mb-2">
                                    <label className="col-md-3 col-form-label">New Password</label>
                                    <div className="col-md-6">
                                        <Input className="form-control" type="password" name="password" onChange={validationPassword.handleChange}
                                        onBlur={validationPassword.handleBlur}
                                        value={validationPassword.values.password || ""}
                                        invalid={
                                            validationPassword.touched.password && validationPassword.errors.password ? true : false
                                        } />
                                        {validationPassword.touched.password && validationPassword.errors.password ? (
                                            <FormFeedback type="invalid">{validationPassword.errors.password}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Row>
                                <Row className="mb-2">
                                    <label className="col-md-3 col-form-label">Confirm Password</label>
                                    <div className="col-md-6">
                                        <Input className="form-control" type="password" name="confirm_password" onChange={validationPassword.handleChange}
                                        onBlur={validationPassword.handleBlur}
                                        value={validationPassword.values.confirm_password || ""}
                                        invalid={
                                            validationPassword.touched.confirm_password && validationPassword.errors.confirm_password ? true : false
                                        } />
                                        {validationPassword.touched.confirm_password && validationPassword.errors.confirm_password ? (
                                            <FormFeedback type="invalid">{validationPassword.errors.confirm_password}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Row>
                                <Row className="mb-2">
                                    <div className="col-md-12 d-flex justify-content-end">
                                        <button className="btn btn-sm btn-primary mx-1" type="submit">Submit</button>
                                        <button className="btn btn-sm btn-danger" onClick={() => {setCodeResetPassword(null); validationPassword.resetForm({password: '', confirm_password: ''}); setSettingModal({...settingModal, button: 'show', title: 'Detail Data User'}); }}>Cancel</button>
                                    </div>
                                </Row>
                                </Form>
                            </Col>
                        </Row>
                    : null }

                    {codeResetPIN !== null ?
                        <Row>
                            <Col md="12" className="justify-content-center">
                                <Form onSubmit={(e) => {
                                    e.preventDefault();
                                    validationPIN.handleSubmit();
                                    return false;
                                }}>
                                <Row className="mb-2">
                                    <label className="col-md-3 col-form-label">New PIN</label>
                                    <div className="col-md-6">
                                        <Input className="form-control" type="password" name="pin" onChange={validationPIN.handleChange}
                                        onBlur={validationPIN.handleBlur}
                                        value={validationPIN.values.pin || ""}
                                        invalid={
                                            validationPIN.touched.pin && validationPIN.errors.pin ? true : false
                                        } />
                                        {validationPIN.touched.pin && validationPIN.errors.pin ? (
                                            <FormFeedback type="invalid">{validationPIN.errors.pin}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Row>
                                <Row className="mb-2">
                                    <label className="col-md-3 col-form-label">Confirm PIN</label>
                                    <div className="col-md-6">
                                        <Input className="form-control" type="password" name="confirm_pin" onChange={validationPIN.handleChange}
                                        onBlur={validationPIN.handleBlur}
                                        value={validationPIN.values.confirm_pin || ""}
                                        invalid={
                                            validationPIN.touched.confirm_pin && validationPIN.errors.confirm_pin ? true : false
                                        } />
                                        {validationPIN.touched.confirm_pin && validationPIN.errors.confirm_pin ? (
                                            <FormFeedback type="invalid">{validationPIN.errors.confirm_pin}</FormFeedback>
                                        ) : null}
                                    </div>
                                </Row>
                                <Row className="mb-2">
                                    <div className="col-md-12 d-flex justify-content-end">
                                        <button className="btn btn-sm btn-primary mx-1" type="submit">Submit</button>
                                        <button className="btn btn-sm btn-danger" onClick={() => { setCodeResetPIN(null); validationPIN.resetForm({pin: '', confirm_pin: ''}); setSettingModal({...settingModal, button: 'show', title: 'Detail Data User'}); }}>Cancel</button>
                                    </div>
                                </Row>
                                </Form>
                            </Col>
                        </Row>
                    : null }
                </ModalBody>
            </Modal>
            
            { confirm_alert.show ? 
                <SweetAlert
                    title= 'Warning!'
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    confirmBtnBsStyle="primary"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        if(confirm_alert.edit == 'password') { HandlerSubmitPassword(confirm_alert.value) }
                        else if(confirm_alert.edit == 'pin') { HandlerSubmitPin(confirm_alert.value); }
                        else if(confirm_alert.edit == 'user') { EditDataUser(); }
                        setconfirm_alert({...confirm_alert, show: false, edit: '', title: '', value: {}});
                    }}
                    onCancel={() => setconfirm_alert({...confirm_alert, show: false, edit: '', text: '', value: {}})}
                >
                    {confirm_alert.text}
                </SweetAlert>
            : null }

            { message_alert.show ?
                <SweetAlert
                    title="Good job!"
                    confirmBtnText="Yes"
                    success
                    confirmBtnBsStyle="primary"
                    onConfirm={() => {
                        setmessage_alert({...message_alert, show: false})
                    }}
                >
                    {message_alert.text}
                </SweetAlert>
            : null }
        </div>
    )
}

ModalDetail.propTypes = {
    toggleModal: PropTypes.any,
    modalShow: PropTypes.any,
    idDetail: PropTypes.any
};

export default withTranslation()(ModalDetail);