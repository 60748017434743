import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";
import { get } from "helpers/api_helper";
import imageDashboard from '../../assets/images/dashboard.svg'


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";

const Dashboard = props => {
  const [dataSiswa, setDataSiswa] = useState({masuk: 0, diJemput: 0, belumDiJemput: 0, expired: 0});

  const getData = async ()=> {
    let responsePickup = await get("/student/all/pickup");
    const data = responsePickup.data;
    const pickup = data.filter(x => x.pickup !== null);
    const masuk = pickup.filter(x => x.pickup.status === 'accepted').length;
    const diJemput = pickup.filter(x => x.pickup.status === 'done').length;
    const expired = pickup.filter(x => x.pickup.status === 'canceled').length;

    const belumDiJemput = data.filter(x => x.pickup === null).length;
    setDataSiswa({...dataSiswa, masuk: masuk, diJemput: diJemput, belumDiJemput: belumDiJemput, expired: expired});
  }

  useEffect (()=>{
    getData();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Tzu Chi</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Pickup")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            <Col className="col-12">
              <Card className="bg-primary bg-opacity-25 px-2 py-5" style={{position: 'relative'}}>
                <CardBody>
                  <div>
                    <CardTitle className="h4 text-primary">Welcome!</CardTitle>
                    <p className="text-primary mb-0">Tzu Chi School Pickup System Management Dashboard</p>
                  </div>
                  <div style={{position: 'absolute', bottom: 0, right: 50}}>
                    <img src={imageDashboard} style={{width: 250}} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-9">
              <Card style={{minHeight: 270}}>
                <CardBody>
                  <CardTitle className="h4">Number of Student</CardTitle>
                  <p className="card-title-desc"></p>
                  <Row>
                    <Col md="4">
                      <div className="bg-info bg-opacity-50 rounded-circle m-auto text-center" style={{width: 150, height: 150}}>
                        <div className="d-flex flex-row justify-content-center align-item-center text-primary fw-bold" style={{lineHeight: 3, fontSize: 50}}>
                          {dataSiswa.masuk}
                        </div>
                      </div>
                      <div className="text-center mt-3">Pickup Requested</div>
                    </Col>

                    <Col md="4">
                      <div className="bg-info bg-opacity-50 rounded-circle m-auto text-center" style={{width: 150, height: 150}}>
                        <div className="d-flex flex-row justify-content-center align-item-center text-primary fw-bold" style={{lineHeight: 3, fontSize: 50}}>
                          {dataSiswa.diJemput}
                        </div>
                      </div>
                      <div className="text-center mt-3">Finished Pickup</div>
                    </Col>

                    <Col md="4">
                      <div className="bg-info bg-opacity-50 rounded-circle m-auto text-center" style={{width: 150, height: 150}}>
                        <div className="d-flex flex-row justify-content-center align-item-center text-primary fw-bold" style={{lineHeight: 3, fontSize: 50}}>
                          {dataSiswa.belumDiJemput}
                        </div>
                      </div>
                      <div className="text-center mt-3">Not Pickuped Yet</div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col className="col-3">
              <Card style={{minHeight: 270}}>
                <CardBody>
                  <CardTitle className="h4">QR Code Expire</CardTitle>
                  <p className="card-title-desc"></p>
                  <Row>
                    <Col md="12">
                      <div className="bg-danger bg-opacity-50 rounded-circle m-auto text-center" style={{width: 150, height: 150}}>
                        <div className="d-flex flex-row justify-content-center align-item-center text-danger fw-bold" style={{lineHeight: 3, fontSize: 50}}>
                          {dataSiswa.expired}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Dashboard);
