import PropTypes, { array } from "prop-types";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Table,
  Form
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";


import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//i18n
import { withTranslation } from "react-i18next";

//redux
import { get } from "helpers/api_helper";
import LoadingSpinner from "components/Common/LoadingSpinner";
import NoData from "components/Common/NoData";
import StudentTable from "./Component/Student/StudentTable";
import ModalAddData from "./Component/Student/ModalAddData";

const Student = props => {
  const [isLoading,setIsLoading] = useState(false);
  const [isExpandLoading,setIsExpandLoading] = useState(false);
  const [fetch,setFetch] = useState(false);
  // Table Data
  const [productData, setProductData] = useState([]);
  const [filteredData,setFilteredData] = useState([]);
  const [dataExpand, setDataExpand] = useState({});
  const [ modal, setModal ] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  }

  const getDataExpand = async (id)=> {
    try {
      setIsExpandLoading(true)
      let response = await get(`/student/get/data?va=${id}`);
      let data = response.data;
      const activeFetcher = data.fetcher.filter(e => !e.is_blocked)
      const dataFether = activeFetcher.map(e => {
        return {id: e.id, username: e.username, full_name: e.full_name, relationship: e.relationship, phone: e.phone, photo_profile: e.photo_profile, avatarErr: false }
      })

      const kelas = data.student[0].kelas;
      const teacher = data.student[0].homeroom_teachers;
      const avatar = data.student[0].photo;

      let responseSibling = await get(`/student/get/data?family_id=${data.student[0].family_id}`);
      const sibling = responseSibling.data.student.filter(x => x.id != data.student[0].id);
      const dataSibling = sibling.map(e => {
        return {id: e.id, name: e.name, kelas: e.kelas, va: e.va, photo: e.photo, avatarErr: false }
      })

      let detail = {
        student:{avatar:avatar},
        avatarErr: false,
        activeFetcher:dataFether,
        kelas: kelas,
        teacher: teacher,
        sibling: dataSibling,
      }
      setDataExpand(detail)
      setIsExpandLoading(false);
    }
    catch(error){
      console.error(error);
      setIsExpandLoading(false);
    }
  }

  const handleExpand = id => {
    const newData = productData.map(e=>{
      if(e.id === id){
        e.isExpand = !e.isExpand;
        getDataExpand(e.va);
      } else {
        e.isExpand = false;
      }
      return e
    })
    setProductData(newData);
  }
  
  const handleSearch = entry => {
    const rawData = productData;
    const result = rawData.filter(e => e.keyExtractor.includes(entry.toLowerCase()) );
    setFilteredData(result);
  }

  const getData = async ()=> {
    setFetch(true)
    try{
      const response = await get("/student/");
      let data = response.data;
      const map = data.map((item, index) => {
        let id = item.id;
        let va = item.va;
        let namaSiswa = item.name;
        let grade = item?.kelas?.split(" ")[0] || "-";
        let kelas = item?.kelas?.split(" ")[1] || "-";
        const result = {
          id:id,
          va:va,
          namaSiswa:namaSiswa,
          grade:grade,
          kelas:kelas,
          isExpand:false,
          keyExtractor: `${va}-${namaSiswa}-${grade}-${kelas}`.toLowerCase()
        }
        return result
      });

      const sortedData = map.sort((a,b)=>a.va - b.va)
      setProductData(sortedData);
      setFilteredData(sortedData)
      setFetch(false)
    }
    catch (error){
      console.log(error,"LOL");
      setFetch(false)
    }
  }

  useEffect (()=>{
    getData();
  }, []);

  const Header = ["VA","Student Name","Grade","Class","Action"]

  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Tzu Chi</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Master Data")}
            breadcrumbItem={props.t("Student & Fetcher")}
          />
          
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">Student & Fetcher</CardTitle>
                  <p className="card-title-desc"></p>
                  <Row className="mb-2">
                    <Col md="6">
                        <div className="search-box me-2 mb-3 d-flex justify-content-start">
                          <Button color="primary" onClick={toggleModal} className="btn-sm">Add Super Fetcher</Button>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="search-box me-2 mb-3 d-flex justify-content-end">
                          <div className="position-relative ">
                            <input className="form-control" type="text" placeholder="Search" onChange={(e)=> handleSearch(e.target.value)}/>
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                    </Col>
                  </Row>
                  <StudentTable data={filteredData} header={Header} handleExpand={handleExpand} dataExpand={dataExpand} setDataExpand={setDataExpand} isLoading={fetch} isExpandLoading={isExpandLoading}/>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        </div>
        <ModalAddData modalShow={modal} toggleModal={toggleModal}/>
    </React.Fragment>
  )
};


Student.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Student);
