import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Button,
  Badge,
  Card,
  CardBody,
  CardTitle,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//i18n
import { withTranslation } from "react-i18next";
import ModalDetail from './Component/Class/ModalDetail';

//redux
import { useSelector, useDispatch } from "react-redux";
import { get } from "helpers/api_helper";

const Class = props => {
  const [modal, setModal] = useState(false);
  const [idClass, setIdClass] = useState(null);
  // Table Data
  const [productData, setProductData] = useState([]);

  const getData = async ()=> {
    let response = await get("/class/all");
    let data = response.data;
    const map = data.map((item, index) => {
      let id = item.id;
      let namaKelas = item.name;
      let grade = item.level_number;
      let waliKelas = item?.homeroom_teacher?.teacher?.full_name || "-";
      let jumlahSiswa = item.pickup.total_absen + '/' + item.total_siswa;
      return { "id": id, "namaKelas": namaKelas, "grade": grade, "waliKelas": waliKelas, "jumlahSiswa": jumlahSiswa };
    });
    setProductData(map);
  }

  useEffect (()=>{
    getData();
  }, []);

  const handlerToggle = (id = null) => {
    if(!modal) { setIdClass(id) }
    else { setIdClass(null) }
    setModal(!modal);
  }

  const formatButton = (cell, row) => {
      if(row.id) {
          return(
            <div key={row.id}>
                <Button className="btn btn-sm btn-primary mx-1" onClick={() => handlerToggle(row.id)}>Detail</Button>
            </div>
          );
      }
  }

  const columns = [{
      dataField: 'id',
      text: 'id',
      sort: true,
      hidden: true,
  }, {
      dataField: 'namaKelas',
      text: 'Class Name',
      sort: true,
  }, {
      dataField: 'grade',
      text: 'Grade',
      sort: true
  }, {
      dataField: 'waliKelas',
      text: 'Form Teacher',
      sort: true
  }, {
      dataField: 'jumlahSiswa',
      text: 'Number of Students',
      sort: true
  }, {
      dataField: 'aksi',
      text: 'Action',
      formatter: formatButton,
      sort: true,
      headerStyle: () => {
        return { width: "20%" };
      }
  }];

  const defaultSorted = [{
      dataField: 'namaKelas',
      order: 'asc'
  }];

  const pageOptions = {
      sizePerPage: 10,
      totalSize: productData.length, // replace later with size(customers),
      custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (productData).length }
  ];

  const CutomButton = (onclick) => {
      return (
          <button style={ { color: 'red' } } onClick={ onClick }>Add rows</button>
      );
  };

  const { SearchBar } = Search;

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Tzu Chi</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={props.t("Master Data")}
              breadcrumbItem={props.t("Kelas")}
            />
            
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Kelas</CardTitle>
                    <p className="card-title-desc"></p>

                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='nip'
                      columns={columns}
                      data={productData}
                    >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='id'
                        columns={columns}
                        data={productData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="12">
                                  <div className="search-box me-2 mb-3 d-flex justify-content-end">
                                    <div className="position-relative ">
                                      <SearchBar {...toolkitProps.searchProps} />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      hover={true}
                                      defaultSorted={defaultSorted}
                                      classes={
                                      "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                  />

                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                      {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>

      <ModalDetail toggleModal={handlerToggle} modalShow={modal} idClass={idClass} />
    </div>
  );
  ActionButton.propTypes = {
    id: PropTypes.number
  }
};


Class.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Class);
