import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
    Badge,
} from "reactstrap";
import { get } from "helpers/api_helper";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';


//i18n
import { withTranslation } from "react-i18next";
import ModalDelegasiTeacher from "./ModalDelegasiTeacher";

//redux
// import { useSelector, useDispatch } from "react-redux";

const Guru = props => {
    const [modal, setmodal] = useState(false);
    const [teacherData, setTeacherData] = useState({id: null, name: ''});
    // Table Data
    const [productData, setProductData] = useState([]);

    const getData = async ()=> {
        let response = await get("/teacher");
        let data = response.data;
        const map = data.map((item, index) => {
            let id = item.id
            let namaGuru = item.full_name;
            let kelas = '-';
            let status = <Badge className="bg-secondary py-1">Partner</Badge>;
            if(item.main_class !== null) {
                kelas = item.main_class.class.name;
                status = <Badge className="bg-success py-1">Form Teacher</Badge>;
            }
            return { "id": id, temporary: item.temoray_class.length, "namaGuru": namaGuru, "kelas": kelas, "status": status };
        });
        setProductData(map);
    }

    useEffect (()=>{
        if(!modal) { getData(); }
    }, [modal]);

    const toggleModal = (id = null, name = '') => {
        if(!modal) { setTeacherData({id: id, name: name}) }
        else { setTeacherData({id: null, name: ''}) }
        setmodal(!modal);
    }

    const formatButton = (cell, row) => {
        if(row.id) {
            return(
                <div key={row.id}>
                    {row.temporary == 0 ?
                        <Button className="btn btn-sm btn-primary" onClick={() => toggleModal(row.id, row.namaGuru)}>Delegate</Button>
                    : 
                        <Badge className="bg-info py-1">On Delegation</Badge>
                    }
                </div>
            );
        }
    }

    const columns = [{
        dataField: 'id',
        text: 'id',
        sort: true,
        hidden: true,
    }, {
        dataField: 'temporary',
        text: 'temporary',
        hidden: true,
    }, {
        dataField: 'namaGuru',
        text: 'Teacher Name',
        sort: true
    }, {
        dataField: 'kelas',
        text: 'Class',
        sort: true
    }, {
        dataField: 'status',
        text: 'Status',
        sort: true
    }, {
        dataField: 'aksi',
        text: 'Action',
        formatter: formatButton,
        sort: true
    }];

    const defaultSorted = [{
        dataField: 'namaGuru',
        order: 'asc'
    }];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: productData.length, // replace later with size(customers),
        custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '15', value: 15 },
        { text: '20', value: 20 },
        { text: '25', value: 25 },
        { text: 'All', value: (productData).length }
    ];

    const CutomButton = (onclick) => {
        return (
            <button style={ { color: 'red' } } onClick={ onClick }>Add rows</button>
        );
    };
    
    const { SearchBar } = Search;

    return ( 
        <div>
            <Row>
                <Col className="col-12">
                    <CardTitle className="h4">Teacher Delegation</CardTitle>
                    <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField='nip'
                        columns={columns}
                        data={productData}
                    >
                    {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                        keyField='id'
                        columns={columns}
                        data={productData}
                        search
                    >
                        {toolkitProps => (
                        <React.Fragment>

                            <Row className="mb-2">
                            <Col md="12">
                                <div className="search-box me-2 mb-2 d-flex justify-content-end">
                                    <div className="position-relative">
                                        <div>
                                            <SearchBar {...toolkitProps.searchProps} />
                                            <i className="bx bx-search-alt search-icon" />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            </Row>

                            <Row>
                            <Col xl="12">
                                <div className="table-responsive">
                                <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    hover={true}
                                    defaultSorted={defaultSorted}
                                    classes={
                                    "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                />

                                </div>
                            </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                />
                                </div>
                                <div className="text-md-right ms-auto">
                                <PaginationListStandalone
                                    {...paginationProps}
                                />
                                </div>
                            </Col>
                            </Row>
                        </React.Fragment>
                        )
                        }
                    </ToolkitProvider>
                    )
                    }</PaginationProvider>
                </Col>
            </Row>

            <ModalDelegasiTeacher modalShow={modal} toggleModal={toggleModal} dataTeacher={teacherData} />
        </div>
    );
};

Guru.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(Guru);
