import React,{useState} from 'react'
import PropTypes, { array } from 'prop-types'
import { Button, Table, Row } from 'reactstrap'
import StudentDetail from './StudentDetail'
import LoadingSpinner from 'components/Common/LoadingSpinner'
import Pagination from './Pagination'
import { propTypes } from 'react-bootstrap-editable'
import ModalEditFamily from './modalEditFamily'
import SweetAlert from "react-bootstrap-sweetalert"

const StudentTable = ({data,header,handleExpand,dataExpand,isLoading,isExpandLoading,setDataExpand}) => {
    const [currentPage,setCurrentPage] = useState(1);
    const [dataPerPage,setDataPerPage] = useState(10);
    const [modal, setModal] = useState(false);
    const [Student, setStudent] = useState({id: null, va: null, name: null});
    const [confirm_alert, setconfirm_alert] = useState(false);

    const toggleModal = (data = null) => {
        if(!modal) { 
            setStudent({id: data.id, va: data.va, name: data.namaSiswa});
            setconfirm_alert(true); 
        }
        else { 
            setStudent({id: null, va: null, name: null});
            setModal(false); 
        }
    }

    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentData = data.slice(indexOfFirstData, indexOfLastData);

    const paginate = pageNumber => setCurrentPage(pageNumber);
    return (
        <>
            <div className='table-responsive'>
                <Table className='table'>
                    <thead>
                        <tr>
                            {
                                header.map((h,i) => <th key={`head-${i}`}>{h}</th>)
                            }
                        </tr>
                    </thead>
                    <tbody>
                        { 
                            isLoading ? 
                                <tr>
                                    <td colSpan={5}><LoadingSpinner/></td>
                                </tr>
                            :
                            currentData.map(e => {
                                return(
                                    <React.Fragment key={e.id}>
                                        <tr>
                                            <td>{e?.va || "N/A"}</td>
                                            <td>{e?.namaSiswa || "N/A"}</td>
                                            <td>{e?.grade || "N/A"}</td>
                                            <td>{e?.kelas || "N/A"}</td>
                                            <td>
                                                <Button onClick={()=>handleExpand(e.id)} color="secondary" className="btn btn-sm">
                                                    <i className={`bx bx-chevron-${e.isExpand ? "down" : "right"}`}/>
                                                </Button>
                                                {e.isExpand ? 
                                                    <Button onClick={()=>toggleModal(e)} color="danger" className="btn btn-sm mx-1">
                                                        Edit
                                                    </Button>
                                                : null}
                                            </td>
                                        </tr>
                                        {   
                                            e.isExpand ?
                                            isExpandLoading ? 
                                            <tr><td colSpan={6}><LoadingSpinner/></td></tr>:
                                            <StudentDetail data={e} dataExpand={dataExpand} setDataExpand={setDataExpand}/>
                                            : null
                                        }
                                    </React.Fragment>
                                )
                            })
                    
                        }
                    </tbody>
                </Table>
                {/* <p className='text-muted'>Total data : {data.length}</p> */}
                <Pagination
                    dataPerPage={dataPerPage}
                    totalData={data.length}
                    paginate={paginate}
                    currentPage={currentPage}
                />
            </div>

            <ModalEditFamily modalShow={modal} toggleModal={toggleModal} Student={Student} handleExpand={handleExpand}/>

            { confirm_alert ? 
                <SweetAlert
                    title= 'Warning!'
                    info
                    showCancel
                    confirmBtnText="Yes"
                    confirmBtnBsStyle="primary"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        setModal(true);
                        setconfirm_alert(false);
                    }}
                    onCancel={() => {
                        setStudent({id: null, va: null, name: null});
                        setconfirm_alert(false);
                    }}
                >
                    Are you sure want to edit this student data
                </SweetAlert>
            : null }
        </>
    )
}

StudentTable.propTypes = {
    data : PropTypes.array.isRequired,
    header : PropTypes.array.isRequired,
    handleExpand : PropTypes.func.isRequired,
    dataExpand : PropTypes.any.isRequired,
    isLoading : PropTypes.bool.isRequired,
    isExpandLoading : PropTypes.bool.isRequired,
    setDataExpand: PropTypes.any

}

export default StudentTable