import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Button,
  Badge,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";
import { get, post } from "helpers/api_helper";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";
import LoadingSpinner from "components/Common/LoadingSpinner";
import NoData from "components/Common/NoData";

const Information = props => {
  const [ students, setStudents ] = useState([]);
  const [ isLoading, setIsloading ] = useState([]);

  const getData = async ()=> {
    setIsloading(true);
    let response = await get("/student/all/data");
    let rawData = response.data;
    let data = rawData.map((item,index)=>{
        item.index = index;
        item.jenis = item.allow_pickup ? <Badge className="bg-secondary">In Charge</Badge> : <Badge className="bg-info">Online</Badge>
        return item
    })
    console.log(data,"data");
    setStudents(data || []);
    setIsloading(false);
  }

  useEffect (()=>{
    getData();
  },[])

  const columns = [{
    dataField: 'va',
    text: 'VA',
    sort: true
  }, {
    dataField: 'name',
    text: 'Student Name',
    sort: true
  }, {
    dataField: 'kelas',
    text: 'Class',
    sort: true
  }, {
    dataField: 'jenis',
    text: 'Status',
    sort: true
  }];


  const defaultSorted = [{
    dataField: 'va',
    order: 'asc'
  }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: students.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (students).length }];


  // Select All Button operation
  const selectRow = {
    mode: 'checkbox',
    hideSelectColumn: true,
  }

  const { SearchBar } = Search;


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Tzu Chi</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Attendance")}
            breadcrumbItem={props.t("Information")}
          />

        </Container>
      <Container>
        <Card>
          <CardBody>
            <Row>
              <h5>Attendance Information</h5>
            </Row>
            <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='id'
                    columns={columns}
                    data={students}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='va'
                        columns={columns}
                        data={students}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>

                            <Row className="mb-2">
                              <Col md="12">
                                <div className="search-box me-2 my-2 d-flex justify-content-end">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />

                                </div>
                              </Col>
                            </Row>
                            {
                              isLoading?
                              <LoadingSpinner/>:
                              <NoData data={students}/>
                            }
                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                {/* <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div> */}
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }</PaginationProvider>

          </CardBody>
        </Card>
      </Container>
      </div>
    </React.Fragment>
  );
};

Information.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Information);
