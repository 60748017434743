import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Table } from 'reactstrap'
import user1 from "../../../../assets/images/users/avatar-blank.png";
import { API_URL } from 'helpers/url_helper';
import { Link } from 'react-router-dom';

const StudentDetail = ({data,dataExpand,setDataExpand}) => {
  const handlerAvatarErr = (item, looping = null) => {
    if(item == 'fetcher') {
      const newData = dataExpand.activeFetcher.map((e, index) => {
        if(looping == index) {
          return {id: e.id, username: e.username, full_name: e.full_name, relationship: e.relationship, phone: e.phone, photo_profile: e.photo_profile, avatarErr: true }
        }
        else { return e}
      })
      setDataExpand({...dataExpand, activeFetcher: newData})
    }
    else if(item == 'sibling') {
      const newData = dataExpand.sibling.map((e, index) => {
        if(looping == index) {
          return {id: e.id, name: e.name, kelas: e.kelas, va: e.va, photo: e.photo, avatarErr: true }
        }
        else { return e}
      })
      setDataExpand({...dataExpand, sibling: newData})
    }
    else if(item == 'student') {
      setDataExpand({...dataExpand, avatarErr: true})
    }
  }

  const detailRender = (data, index) => {
    return(
      <div className="mb-3" key={data.id}>
        <Row>
          <Col md="4">
            <img 
              src={data.avatarErr ? user1 : `${API_URL + `/` + data?.photo_profile || ""}`} 
              onError={() => handlerAvatarErr('fetcher', index)}
              className="avatar-lg img-fluid rounded" style={{objectFit:"cover"}} 
            />
          </Col>
          <Col md="8">
            <table className="table table-borderless table-sm">
              <tbody>
                <tr>
                  <td className="card-title-desc">Name</td>
                  <td>{data.full_name}</td>
                </tr>
                <tr>
                  <td className="card-title-desc">Realtion</td>
                  <td>{data.role_id == 5?"Super Fetcher":data.relationship}</td>
                </tr>
                <tr>
                  <td className="card-title-desc">Phone</td>
                  <td>{data?.phone || "-"}</td>
                </tr>
                <tr>
                  <td className="card-title-desc">Username</td>
                  <td><Link to={`/user?username=${data.username}`}> {data?.username || "-"}</Link></td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>  
      </div>
    )
  }

  const detailSibling = (data, index) => {
    return(
      <div className="mb-3" key={data.id}>
        <Row>
          <Col md="4">
            <img 
              src={data.avatarErr ? user1 : `${API_URL + `/` + data?.photo || ""}`} 
              onError={() => handlerAvatarErr('sibling', index)}
              className="avatar-lg img-fluid rounded" style={{objectFit:"cover"}} 
            />
          </Col>
          <Col md="8">
            <h6>Sibling Detail</h6>
            <table className="table table-borderless table-sm">
              <tbody>
                <tr>
                  <td className="card-title-desc">Name</td>
                  <td>{data.name}</td>
                </tr>
                <tr>
                  <td className="card-title-desc">VA</td>
                  <td>{data.va}</td>
                </tr>
                <tr>
                  <td className="card-title-desc">Class</td>
                  <td>{data.kelas}</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <tr key={`${data.va}-${Math.random(100)}`}>
      <td colSpan="6">
        <div className="bg-light rounded p-5">
          <Row>
            <Col md="6" className="">
              <Row>
                <Col md="4">
                  <img 
                    src={dataExpand.avatarErr ? user1 : `${API_URL + `/` + dataExpand?.student?.avatar || ""}`} 
                    onError={() => handlerAvatarErr('student')} 
                    className="avatar-lg img-fluid rounded" style={{objectFit:"cover"}} 
                  />
                </Col>
                <Col md="8">
                  <h6>Student Detail</h6>
                  <Table className="table table-borderless table-sm">
                    <tbody>
                      <tr>
                        <td className="card-title-desc">Name</td>
                        <td>{data.namaSiswa}</td>
                      </tr>
                      <tr>
                        <td className="card-title-desc">VA</td>
                        <td>{data.va}</td>
                      </tr>
                      <tr>
                        <td className="card-title-desc">Class</td>
                        <td>{data.grade} {data.kelas}</td>
                      </tr>
                      <tr>
                        <td className="card-title-desc">HR. Teacher</td>
                        <td>{dataExpand.teacher}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <h6>Siblings</h6>
                  {
                      dataExpand.sibling.length === 0 ? 
                      <p className='text-muted'>No Sibling</p>
                      : null
                  }
                {dataExpand.sibling.map((x, index) => detailSibling(x, index))}
              </Row>
            </Col>
            <Col md="6" className="">
              <h6>Fetcher Detail</h6>
                  {
                      dataExpand.activeFetcher.length === 0 ? 
                      <p className='text-muted'>No Active Fetcher</p>
                      : null
                  }
              {dataExpand.activeFetcher.map((a, index) => detailRender(a,index))}
            </Col>
          </Row>
        </div>
      </td>
    </tr>
  )
}

StudentDetail.propTypes = {
    data : PropTypes.any,
    dataExpand : PropTypes.any,
    setDataExpand: PropTypes.any

}

export default StudentDetail