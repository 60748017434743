import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
} from "reactstrap";
import { get } from "helpers/api_helper";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';


//i18n
import { withTranslation } from "react-i18next";
import ModalDelegasiStudent from "./ModalDelegasiStudent";

//redux
// import { useSelector, useDispatch } from "react-redux";

const Siswa = props => {
    const [modal, setmodal] = useState(false);
    const [idClass, setIdClass] = useState(null);
    // Table Data
    const [productData, setProductData] = useState([]);

    const getData = async ()=> {
        let response = await get("/class/all");
        let data = response.data;
        const map = data.map((item, index) => {
            let id = item.id;
            let kelas = item.name;
            let grade = item.level_number;
            let jumlahSiswa = item.pickup.total_absen + '/' + item.total_siswa;
            return { "id": id, "kelas": kelas, "grade": grade, "jumlahSiswa": jumlahSiswa };
        });
        setProductData(map);
    }

    useEffect (()=>{
        getData();
    }, []);

    const toggleModal = (id = null) => {
        if(!modal) { setIdClass(id); }
        else { setIdClass(null); }
        setmodal(!modal);
    }

    const formatButton = (cell, row) => {
        if(row.id) {
            return(
                <div key={row.id}>
                    <Button className="btn btn-sm btn-primary" onClick={() => toggleModal(row.id)} >Delegate</Button>
                </div>
            );
        }
    }

    const columns = [{
        dataField: 'id',
        text: 'id',
        sort: true,
        hidden: true,
    }, {
        dataField: 'kelas',
        text: 'Class',
        sort: true
    }, {
        dataField: 'grade',
        text: 'Grade',
        sort: true
    }, {
        dataField: 'jumlahSiswa',
        text: 'Number of Students',
        sort: true
    }, {
        dataField: 'aksi',
        text: 'Action',
        formatter: formatButton,
        sort: true
    }];

    const defaultSorted = [{
        dataField: 'kelas',
        order: 'asc'
    }];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: productData.length, // replace later with size(customers),
        custom: true,
    }

    // Custom Pagination Toggle
    const sizePerPageList = [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '15', value: 15 },
        { text: '20', value: 20 },
        { text: '25', value: 25 },
        { text: 'All', value: (productData).length }
    ];

    const CutomButton = (onclick) => {
        return (
            <button style={ { color: 'red' } } onClick={ onClick }>Add rows</button>
        );
    };
    
    const { SearchBar } = Search;

    return ( 
        <div>
            <Row>
                <Col className="col-12">
                    <CardTitle className="h4">Student Delegation</CardTitle>

                    <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField='nip'
                        columns={columns}
                        data={productData}
                    >
                    {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                        keyField='id'
                        columns={columns}
                        data={productData}
                        search
                    >
                        {toolkitProps => (
                        <React.Fragment>

                            <Row className="mb-2">
                            <Col md="12">
                                <div className="search-box me-2 mb-3 d-flex justify-content-end">
                                <div className="position-relative">
                                    <div>
                                        <SearchBar
                                        {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                    </div>
                                </div>
                                </div>
                            </Col>
                            </Row>

                            <Row>
                            <Col xl="12">
                                <div className="table-responsive">
                                <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    hover={true}
                                    defaultSorted={defaultSorted}
                                    classes={
                                    "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                />

                                </div>
                            </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                />
                                </div>
                                <div className="text-md-right ms-auto">
                                <PaginationListStandalone
                                    {...paginationProps}
                                />
                                </div>
                            </Col>
                            </Row>
                        </React.Fragment>
                        )
                        }
                    </ToolkitProvider>
                    )
                    }</PaginationProvider>
                </Col>
            </Row>

            <ModalDelegasiStudent modalShow={modal} toggleModal={toggleModal} idClass={idClass} />
        </div>
    );
};

Siswa.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(Siswa);
