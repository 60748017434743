import React from 'react';
import PropTypes from "prop-types";
import { Row } from 'reactstrap';

const NoData = ({data}) => {

    return (
        <div>

        {
            data.length == 0 ?
            <Row className="text-center">
                <p>No data available</p>
            </Row>: null
        }
    </div>
    );
}

export default NoData;

NoData.propTypes = {
    data:PropTypes.array.isRequired
}
