import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Modal, 
  ModalHeader,
  ModalBody,
  FormFeedback,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert"

import { get, post} from "helpers/api_helper";

//i18n
import { withTranslation } from "react-i18next";
import { set } from "lodash";

const ModalDelegasiStudent = (props) => {
    const [confirm_alert, setconfirm_alert] = useState(false);
    const [message_alert, setmessage_alert] = useState(false);
    const [selectErr, setSelectErr] = useState({textStudent: false, textClass: false, alert: false, alertText: ''});
    const [student, setStudent] = useState([]);
    const [studentDelegasi, setStudentDelegasi] = useState([]);
    const [selectedGroup, setselectedGroup] = useState(null);
    const [classData, setClassData] = useState([]);

    const getDataClass = async () => {
        let response = await get(`/class/all`);
        let data = response.data;
        const map = data.map((item) => {
            return { label: item.name, value: item.id }
        });
        setClassData(map.filter(x => x.value != props.idClass));
    }

    let dataOptions = [{label: 'choose class', value: null}];
    classData.map((item) => {
        dataOptions.push(item);
    });

    const getDataStudent = async () => {
        let response = await get(`/class/detail/${props.idClass}`);
        let data = response.data.student;
        const map = data.map((item) => {
            return { student_id: item.id, student_class_id: item.class_id, va : item.va, name: item.name };
        });
        setStudent(map);
    }

    useEffect(() => {
        if(props.idClass !== null) { 
            setSelectErr({...selectErr, textStudent: false, textClass: false, alert: false, alertText: ''})
            getDataStudent(); 
            getDataClass();
            setselectedGroup(null);
        }
        else { setStudent([]) }
    }, [props.modalShow]);

    const handlerSubmit = () => {
        const mapClass = $("input[name='check_student']:checked").map(function() {
            const value = this.value
            const data = student.find(x => x.student_id == value);
            return { student_id: data.student_id, student_class_id: data.student_class_id, va : data.va };
        });
        setStudentDelegasi(mapClass);
        if(mapClass.length == 0 && selectedGroup == null) {
            setSelectErr({...selectErr, textStudent: true, textClass: true, alert: true, alertText: 'class and student'})
        }
        else if(mapClass.length == 0) {
            setSelectErr({...selectErr, textStudent: true, alert: true, alertText: 'student'})
        }
        else if(selectedGroup == null) {
            setSelectErr({...selectErr, textClass: true, alert: true, alertText: 'class'})
        }
        else {
            setconfirm_alert(true);
            setSelectErr({...selectErr, textStudent: false, textClass: false, alert: false, alertText: ''})
        }
    }

    const handlerCheckStudent = () => {
        const mapClass = $("input[name='check_student']:checked").length;
        if(mapClass == 0) { setSelectErr({...selectErr, textStudent: true}) }
        else { setSelectErr({...selectErr, textStudent: false}) }
    };

    function handleSelectGroup(e) {
        if(e.value == null) { setSelectErr({...selectErr, textClass: true}) }
        else { setSelectErr({...selectErr, textClass: false}) }
        setselectedGroup(e.value);
    }

    const postStudentDelegasi = async () => {
        let student = [];
        studentDelegasi.map((x) => {
            student.push(studentDelegasi[x]);
        })
        const body = {
            to_class_id: selectedGroup,
            student: student
        }

        let response = await post(`/teacher/delegasi_student`, body);
        if(response) {
            setselectedGroup(null);
            setmessage_alert(true);
        }
    }
   
    return(
        <div>
            <Modal
                size="lg"
                centered={true}
                isOpen={props.modalShow}
                toggle={props.toggleModal}
                scrollable={true}
            >
                <ModalHeader toggle={props.toggleModal}>Student Delegation</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg="12">
                            <Label className="fw-bolder">Choose Student</Label>
                            { selectErr.textStudent ?
                                <div>
                                    <span className="text-danger">This field is required, please choose studednt</span>
                                </div>
                            : null }
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Check</th>
                                        <th>Student Name</th>
                                        <th>Va</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { student.length > 0 ? 
                                        student.map((x, index) => {
                                            return(
                                                <tr key={index}>
                                                    <td><input type="checkbox" className="form-check-input" name="check_student" value={x.student_id} onChange={handlerCheckStudent} /></td>
                                                    <td>{x.name}</td>
                                                    <td>{x.va}</td>
                                                </tr>
                                            )
                                        })
                                    : 
                                        <tr>
                                            <td colSpan={3} className="text-center">No Student</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Label className="col-md-3 col-form-label fw-bolder">Choose Class</Label>
                        <div className="col-md-6">
                            <Select
                                value={dataOptions.find(obj => obj.value === selectedGroup)}
                                onChange={(e) => {
                                    handleSelectGroup(e);
                                }}
                                options={dataOptions}
                                classNamePrefix="select2-selection"
                            />
                            { selectErr.textClass ?
                                <span className="text-danger">This field is required, please choose the class</span>
                            : null }
                        </div>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <div className="d-flex flex-row justify-content-end">
                                <button className="btn btn-sm btn-primary mx-1" onClick={handlerSubmit}>Submit</button>
                                <button className="btn btn-sm btn-danger" onClick={props.toggleModal}>Cancel</button>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            
            { confirm_alert ? 
                <SweetAlert
                    title= 'Warning!'
                    info
                    showCancel
                    confirmBtnText="Yes"
                    confirmBtnBsStyle="primary"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        postStudentDelegasi();
                        setconfirm_alert(false);
                    }}
                    onCancel={() => {setconfirm_alert(false);}}
                >
                    Are you sure to delegate the student
                </SweetAlert>
            : null }

            { message_alert ?
                <SweetAlert
                    title="Success!"
                    confirmBtnText="Yes"
                    success
                    confirmBtnBsStyle="primary"
                    onConfirm={() => {
                        setmessage_alert(false);
                        props.toggleModal();
                    }}
                >
                    Success to delegate Student
                </SweetAlert>
            : null }

            { selectErr.alert ?
                <SweetAlert
                    title="Upsss!"
                    confirmBtnText="Yes"
                    danger
                    confirmBtnBsStyle="primary"
                    onConfirm={() => {
                        setSelectErr({...selectErr, alert: false});
                    }}
                >
                    Please select a {selectErr.alertText} first
                </SweetAlert>
            : null }
        </div>
    )
}

ModalDelegasiStudent.propTypes = {
    toggleModal: PropTypes.any,
    modalShow: PropTypes.any,
    idClass: PropTypes.any,
};

export default withTranslation()(ModalDelegasiStudent);