import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Badge,
  Button,
  Card,
  CardBody,
  Spinner,
} from "reactstrap";

import Select from "react-select";
import { Link } from "react-router-dom";
import { get, post } from "helpers/api_helper";
import Flatpickr from "react-flatpickr";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { forEach } from "lodash";
import LoadingSpinner from "components/Common/LoadingSpinner";
import NoData from "components/Common/NoData";
import { API_URL } from "helpers/url_helper";

const Laporan = props => {
  const todayDate = moment().format(`YYYY-MM-DD`);
  const tommorow = moment().add('days',1).format(`YYYY-MM-DD`);
  const yesterday = moment().subtract('days',1).format(`YYYY-MM-DD`);

  const [ report,setReport ] = useState([]);
  const [ startDate, setStartDate ] = useState(todayDate);
  const [ endDate, setEndDate ] = useState(todayDate);
  const [ fetch,setFetch] = useState(false);
  
  const [ classes, setClasses] = useState([{label: "mark", options: [{label:'Select Mark',value:null}]}]);
  const [ mark, setMark ] = useState([{label: "class", options: [{label:'Select Class',value:null}]}]);
  const [ reportFiltered, setReportFiltered ] = useState([]);
  const [ filter,setFilter ] = useState({classes:null,mark:null});
  const [ isLoading, setIsloading ] = useState(false);
  const [ isDownload, setIsDownload ] = useState(false);
  const [ expand, setExpand ] = useState([]);

  const getData = async ()=> {
    setIsloading(true);
    try{
      let response = await get(`/pickup/log/date?start_date=${startDate}&end_date=${endDate}`);
      console.log(response);
      let date = Object.keys(response.data)
      let newDate = [];
      for (const [key,value] of Object.entries(response.data)){
        let pickup = value.map(item => {
          item.tanggal = moment(item.updated_at).format(`DD/MM/YYYY`)
          return item
        })
        pickup.forEach(element=> newDate.push(element))
      }
      newDate.sort((a,b)=> moment(b.tanggal).isAfter(a.tanggal)?1:-1)
      setReport(recycleData(newDate));
      setReportFiltered(recycleData(newDate) || []);
      setFilterData(recycleData(newDate))
      setIsloading(false)
    } catch (error) {
      console.error(error?.response || error)
      setIsloading(false)
    }
    
  }

  const recycleData = data => {
   const newData =  data.map(item => {
      let result = {};
      result.id = item.id
      result.name = item?.student?.name || "N/A"
      result.kelas = item?.student?.kelas || "N/A"
      result.class_id = item?.student?.class_id || "N/A"
      result.va = item?.student?.va || "N/A"
      result.fetcher = item?.driver?.full_name || "N/A"
      result.teacher = item?.release_teacher?.full_name || "N/A"
      result.mark = item?.action || "N/A"
      result.date = item?.tanggal || "N/A"
      result.key = `${item.student.va}-${item.tanggal}`
      result.timeline = [
        {actor:item?.driver?.full_name || "Fetcher" , time:item.start_date, icon:"bx-barcode", desc:"Pickup request are made",fade:false,color:"primary"},
        (item.end_date) ?
        {actor:item?.release_teacher?.full_name || "Teacher", time:item.end_date, icon:"bx-user",desc:"Releasing student",fade:false,color:"primary"}
        :
        {actor:item?.driver?.full_name || "Fetcher", time:item.due_date, icon:"bx-timer",desc:"QR Expired",fade:true,color:"danger"}

      ]
      return result;
    })
    return newData;
  }

  const timelineDetail = data => {
    return (
      <React.Fragment>
        <div className="p-2">
          <ul className="verti-timeline list-unstyled">
            {data.timeline.map((e, key) => {
              return(
                <li key={key} className="event-list">
                  <div className="event-timeline-dot">
                    <i className={`bx bx-right-arrow-circle ${e.fade?"bx-fade-right":null}`}/>
                  </div>
                  <div className="d-flex">
                  <div className="me-3">
                    <i className={`bx ${e.icon} h2 text-${e.color}`}/>
                  </div>
                  <div className="flex-grow-1">
                    <div>
                      <h5>{e.actor}</h5>
                      <p className="text-muted my-0">
                        {moment(e.time).format(`HH:MM - DD/MM/YYYY`)}
                      </p>
                      <p className="text-muted">
                        {e.desc}
                      </p>
                    </div>
                  </div>
                  </div>
                </li>
                )
            })}
                </ul>
                </div>
                </React.Fragment>
    )
  }

  const markFormat = (cell,row) => {
    return(
      row.mark === `QRCode Release` ?
      <Badge className="bg-success py-1">QR Code</Badge> :
      row.mark === `Manual Release` ?
      <Badge className="bg-info py-1">Manual Release</Badge> :
      <Badge className="bg-danger py-1">Canceled</Badge> 
    )
  }

  const setFilterData = raw => {
    raw.map(item => {
      if(item.mark === 'QRCode Release') { item.mark = 'QR Code' }
      else if(item.mark === 'Manual Release') { item.mark = 'Manual Release' }
      else { item.mark = 'Canceled' }
    });

    const mark = raw.map(item => item.mark).filter((value,i,array) => array.indexOf(value) === i)
    const kelas = raw.map(item => item.kelas).filter((value,i,array) => array.indexOf(value) === i)
    const class_id = raw.map(item => item.class_id).filter((value,i,array) => array.indexOf(value) === i)

    let markFilterOptions = [{label:'Select Mark',value:null}]
    let classFilterOptions = [{label:'Select Class',value:null}]

    mark.map(item => {
      markFilterOptions.push({label:item,value:item})
    });
    kelas.map((item, index) => {
      classFilterOptions.push({label:item,value:class_id[index]})
    })

    let markFilter = {
      label:"mark",
      options:markFilterOptions
    }
    let classFilter = {
      label:"class",
      options:classFilterOptions
    }
    setMark([markFilter]);
    setClasses([classFilter]);
  }

  const clearFilter = () => {
    setFilter({mark:null,classes:null});
  }

  const generateDownload = async ()=> {
    if(!isDownload){
      setIsDownload(true)
      
      // &status=accepted&class_id=1
      let url = `/pickup/log/date?start_date=${startDate}&end_date=${endDate}&download=excel`
      if(filter.classes !== null) {
        url += `&class_id=${filter.classes}`;
      }
      console.log(url);
      let response = await get(url);
      window.open(API_URL + "/" + response.data.url,"_blank")
      
      setIsDownload(false)
    }
    else {
      console.log("Sibuk");
    }
  }

  useEffect (()=>{
    getData();
  },[fetch])

  useEffect(()=>{
    let mark = 'N/A'
    if(filter.mark) {
      if(filter.mark === 'QR Code') { mark = 'QRCode Release' }
      else if(filter.mark === 'Manual Release') { mark = 'Manual Release' }
    }
    const newData = report.filter(item => {
      return ((filter?.mark) ? (mark === item.mark) : true) && ((filter?.classes) ? (filter?.classes === item.class_id) : true)
    })
    setReportFiltered(newData)
  },[filter])

  const expandHandler = (row,isExpand,rowIndex,e) => {
    console.log("Trigger");
    if(isExpand){
      setExpand([row.id])
    } else {
      setExpand(expand.filter(e => e != row.id));
    }
  }
  
  const expandRow = {
    renderer: timelineDetail,
    expanded: expand,
    onExpand: expandHandler,
    showExpandColumn: true,
    expandHeaderColumnRenderer: () => {
      return null
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
         <b><i className="bx bx-chevron-down" /></b>
          );
        }
        return (
        <b><i className="bx bx-chevron-right" /></b>
      );
    }

  };
  const columns = [{
    dataField: 'id',
    text: 'ID',
    sort: true,
    hidden:true,
  },{
    dataField: 'key',
    text: 'key',
    sort: true,
    hidden:true,
  },{
    dataField: 'class_id',
    text: 'class_id',
    sort: true,
    hidden:true,
  },{
    dataField: 'va',
    text: 'VA',
    sort: true,
  }, {
    dataField: 'name',
    text: 'Student Name',
    sort: true
  }, {
    dataField: 'kelas',
    text: 'Classes',
    sort: true
  },{
    dataField: 'fetcher',
    text: 'Fetcher',
    sort: false
  }, {
    dataField: 'teacher',
    text: 'Teacher',
    sort: false
  }, {
    dataField: 'mark',
    text: 'Mark',
    sort: false,
    formatter:markFormat,
  }, {
    dataField: 'date',
    text: 'Date',
    sort: true
  }];



  const defaultSorted = [{
    dataField: 'id',
    order: 'desc'
  }];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: report.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: (report).length }];


  // Select All Button operation
  const selectRow = {
    mode: 'checkbox',
    hideSelectColumn: true,
  }

  const { SearchBar } = Search;


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Tzu Chi</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Pickup")}
            breadcrumbItem={props.t("Laporan")}
          />

        </Container>
      <Container>
        <Card>
          <CardBody>
            <Row>
              <h5>Pickup Report</h5>
            </Row>
            <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='id'
                    columns={columns}
                    data={reportFiltered}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='id'
                        columns={columns}
                        data={reportFiltered}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>

                            <Row className="mb-2">
                              <Col md="2">
                                <div className="my-2">
                                  <Flatpickr
                                  className="form-control d-block"
                                  placeholder="Start date"
                                  options={{
                                    dateFormat:`d/m/Y`,
                                    maxDate:`today`
                                  }}
                                  onChange={(e)=>setStartDate(moment(new Date(e)).format(`YYYY-MM-DD`))}
                                  />
                                  {/* <input
                                    className="form-control"
                                    type="date"
                                    defaultValue="2019-08-19"
                                    id="example-date-input"
                                  /> */}
                                </div>
                              </Col>
                              <Col md="2">
                                <div className="my-2">
                                <Flatpickr
                                  className="form-control d-block"
                                  placeholder="End date"
                                  options={{
                                    dateFormat:`d/m/Y`,
                                    maxDate:`today`,
                                  }}
                                  onChange={(e)=>setEndDate(moment(new Date(e)).format(`YYYY-MM-DD`))}
                                  />
                                </div>
                              </Col>
                              <Col md="2">
                                <div className="my-2">
                                  <Button color="primary" className="px-4" onClick={()=>setFetch(!fetch)}>Apply</Button>
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="search-box me-2 my-2 d-flex justify-content-end">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row className="my-2">
                              <Col md="2">
                                <div className="my-2">
                                  <Select
                                    placeholder="Select Classes"
                                    value={classes[0].options.find(obj => obj.value === filter.classes)}
                                    options={classes}
                                    onChange={item => setFilter({...filter,classes:item.value})}
                                  />
                                </div>
                              </Col>
                              <Col md="2">
                                <div className="my-2">
                                  <Select
                                    placeholder="Select Mark"
                                    value={mark[0].options.find(obj => obj.value === filter.mark)}
                                    options={mark}
                                    onChange={item => setFilter({...filter,mark:item.value})}
                                  />
                                </div>
                              </Col>
                              <Col md="2">
                                <div className="my-2">
                                  <Button color="primary" className="btn-block px-4" onClick={()=>clearFilter()}>Clear</Button>
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="d-flex justify-content-end my-2">
                                  <Button color="success" className="btn" onClick={()=>generateDownload()} disabled={reportFiltered.length==0?true:false}>{
                                    isDownload ? 
                                    <>
                                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> Loading
                                    </> :
                                    `Generate Report`
                                  }</Button>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    expandRow={ expandRow }
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />

                                </div>
                              </Col>
                            </Row>
                            {
                             isLoading?
                              <LoadingSpinner/>:
                              <NoData data={reportFiltered}/>
                            }
                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                {/* <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div> */}
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }</PaginationProvider>

          </CardBody>
        </Card>
      </Container>
      </div>
    </React.Fragment>
  );
};

Laporan.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Laporan);
