import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { get } from "helpers/api_helper";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import "./datatables.scss"

//i18n
import { withTranslation } from "react-i18next";
import { ModalAdd, ModalDetail } from "./Components";
import { useLocation } from "react-router-dom";

//redux
// import { useSelector, useDispatch } from "react-redux";

const Delegasi = props => {
  // Table Data
  const [productData, setProductData] = useState([]);
  const [modalAdd, setmodalAdd] = useState(false);
  const [modalDetail, setmodalDetail] = useState(false);
  const [idDetail, setIdDetail] = useState(null);

  const toggleModal = () => setmodalAdd(!modalAdd);
  const toggleModalDetail = (id = null) => {
    if(!modalDetail) { setIdDetail(id) }
    else { setIdDetail(null) }
    setmodalDetail(!modalDetail);
  }
 
  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search),[search])
  }

  let query = useQuery();

  const getData = async ()=> {
    let response = await get("/user");
    let data = response.data;
    const map = data.map((item, index) => {
      let id = item.id
      let namaUser = item.full_name;
      let email = item.email;
      let noTelp = item.phone;
      let username = item.username
      return { "id": id , "namaUser": namaUser, "username":username, "email": email, "noTelp": noTelp };
    });
     
    const searchQuery = query.get('username');
    if(searchQuery){
      const filteredUser = map.filter(x => x.username === searchQuery);
      setProductData(filteredUser);
    } else {
      setProductData(map);
    }
  }

  useEffect (()=>{
    if (!modalDetail) { getData() }
  }, [modalDetail]);

  const formatButton = (cell, row) => {
      if(row.id) {
          return(
            <div key={row.id}>
                <Button className="btn btn-sm btn-primary mx-1" onClick={() => toggleModalDetail(row.id)}>Detail</Button>
            </div>
          );
      }
  }

  const columns = [{
      dataField: 'id',
      text: 'id',
      sort: true,
      headerStyle: () => {
        return { width: "10%" };
      }
  },{
      dataField: 'namaUser',
      text: 'Full Name',
      sort: true,
  },{
      dataField: 'username',
      text: 'Username',
      sort: true,
  }, {
      dataField: 'email',
      text: 'Email',
      sort: true
  }, {
      dataField: 'aksi',
      text: 'Action',
      formatter: formatButton,
      sort: true,
      headerStyle: () => {
        return { width: "20%" };
      }
  }];

  const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
  }];

  const pageOptions = {
      sizePerPage: 10,
      totalSize: productData.length, // replace later with size(customers),
      custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (productData).length }
  ];

  const CutomButton = (onclick) => {
      return (
          <button style={ { color: 'red' } } onClick={ onClick }>Add rows</button>
      );
  };
  
  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
            <title>Tzu Chi</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
              title={props.t("User")}
              breadcrumbItem={props.t("User Management")}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">User Management</CardTitle>
                  <p className="card-title-desc"></p>

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='nip'
                    columns={columns}
                    data={productData}
                  >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField='id'
                      columns={columns}
                      data={productData}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col md="12">
                                <div className="search-box me-2 mb-3 d-flex flex-row justify-content-between">
                                  <div className="position-relative ">
                                    {/* <Button className="btn btn-sm btn-primary mx-1" onClick={toggleModal}>Add User</Button> */}
                                  </div>
                                  <div className="position-relative ">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    hover={true}
                                    defaultSorted={defaultSorted}
                                    classes={
                                    "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                />

                              </div>
                            </Col>
                          </Row>

                          <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                />
                              </div>
                              <div className="text-md-right ms-auto">
                                <PaginationListStandalone
                                    {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <ModalAdd modalShow={modalAdd} toggleModal={toggleModal}/>
        <ModalDetail modalShow={modalDetail} toggleModal={toggleModalDetail} idDetail={idDetail}/>

      </div>
    </React.Fragment>
  );
};

Delegasi.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Delegasi);
